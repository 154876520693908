export const currencyList = {
    "USD": "en-US",
    "EUR": "en-GB",
    "JPY": "ja-JP",
    "GBP": "en-GB",
    "AUD": "en-AU",
    "CAD": "en-CA",
    "CHF": "de-CH",
    "CNY": "zh-CN",
    "INR": "en-IN",
    "RUB": "ru-RU",
    "BRL": "pt-BR",
    "ZAR": "en-ZA",
    "SAR": "ar-SA",
    "KRW": "ko-KR",
    "MXN": "es-MX",
    "IDR": "id-ID",
    "TRY": "tr-TR",
    "MYR": "ms-MY",
    "SGD": "en-SG",
    "PKR": "en-PK",
    "THB": "th-TH",
    "VND": "vi-VN",
    "NGN": "en-NG",
    "HKD": "zh-HK",
    "PLN": "pl-PL",
    "SEK": "sv-SE",
    "NOK": "nb-NO",
    "DKK": "da-DK",
    "ILS": "he-IL",
    "AED": "ar-AE",
    "EGP": "ar-EG",
    "KWD": "ar-KW",
    "QAR": "ar-QA",
    "LKR": "si-LK",
    "BDT": "bn-BD",
    "NPR": "ne-NP",
    "OMR": "ar-OM",
    "BHD": "ar-BH",
    "PHP": "en-PH",
    "UAH": "uk-UA",
    "CZK": "cs-CZ",
    "HUF": "hu-HU",
    "NZD": "en-NZ",
    "COP": "es-CO",
    "ARS": "es-AR",
    "CLP": "es-CL",
    "PEN": "es-PE",
    "RON": "ro-RO",
    "TWD": "zh-TW",
    "KES": "en-KE",
    "TZS": "sw-TZ"
}

export const getLocaleByCurrency = (currencyAbbreviation) => {
    return currencyList[currencyAbbreviation] || 'en-US';
  };

export const formatCurrency = (value, currencyAbbreviation) => {
    const locale = getLocaleByCurrency(currencyAbbreviation);
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyAbbreviation, // Use the currency code (e.g., INR, USD, PKR, etc.)
    }).format(value);
  };


/**
 * Formats a number based on the provided locale.
 * @param {number} number - The number to format.
 * @returns {string} - The formatted number as a string.
 */
export const formatNumberByAbbreviation = (number, currencyAbbreviation) => {
    const locale =  currencyList[currencyAbbreviation] || 'en-US';
    if (number === undefined || number === null) {
      return '';
    }
  
    return new Intl.NumberFormat(locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 3,
    }).format(number);
};
  
  /**
   * Formats a number and adds the currency symbol.
   * @param {number} number - The number to format.
   * @param {string} currency - The currency code (e.g., 'INR', 'USD', 'EUR').
   * @returns {string} - The formatted currency string with the symbol.
   */
export const formatCurrencyWithSymbol = (number, currency) => {
    const locale =  currency ? currencyList[currency] || 'en-US':'en-US' ;
    if (number === undefined || number === null) {
      return '';
    }
  
    let formattedCurrency = currency? new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(number):
    new Intl.NumberFormat(locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(number);

    return formattedCurrency.replace(/(\p{Sc})(\S)/u, '$1 $2');
  };
