import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Chip,
  Container,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Grid,
  Stack,
  Divider,
} from '@mui/material';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import AccClientLevel1Table from '../accClientLevel1Table';
import { getAccountingDetails, addAdvanceAmmount } from 'src/services/accounting';
import { useParams, useRouter } from 'src/routes/hooks';
import { calculateContractAmount, calculateDiscountAmount, calculateFinalAmount } from 'src/utils/quoteHelperFunc';
import Ledger from 'src/components/accounting/Ledger';
import _ from 'lodash';
import ContractAmountDisplay from 'src/components/common/ContractAmountDisplay';
import { HasAccess, usePermify } from '@permify/react-role';
import { deleteUploadedManualFile, setl2complete, uploadManualFile } from 'src/services/quotes';
import { useSnackbar } from 'src/components/snackbar';
import { addNewNotification } from 'src/services/notification';
import  moment  from 'moment';
import UploadManualFiles from 'src/components/upload/UploadManualFiles';
import DeleteManualAlert from 'src/sections/Quote/DeleteManualAlert';
import { generateClientLedgerPdf } from 'src/services/pdfGeneration';
import { formatCurrencyWithSymbol } from 'src/utils/currencyFormat';


export default function AccClientLevel1View() {
  const settings = useSettingsContext();
  const { isAuthorized, isLoading } = usePermify();
  const [backendAuth, setBackendAuth] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { id } = useParams();
  const [response, setResponse] = useState();
  const [advance, setAdvance] = useState();
  const router = useRouter();
  const [refresh, setRefresh] = useState(0);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [alertTitle, setAlertTitle] = useState("");
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [deleteManualBody, setDeleteManualBody] = useState({});
  const [seletedFilesName, setSelectedFilesName] = useState();
  useEffect(() => {
    if (id) {
      getAccountingDetails(id)
        .then((response) => {
          setResponse(response);
        })
        .catch((error) => { });
    }
    const callAuth = async() => {
      let tempAuth = await isAuthorized([],"backend");
      await setBackendAuth(tempAuth)
    }
  callAuth();
  }, [id, refresh]);

  const onCompleteL2 = () => {
    setLoading(true);
    setl2complete(id)
      .then(() => {
        setLoading(false);
        enqueueSnackbar('Marked l2 task completed');
        router.push('/home/accounting');
      })
      .catch((err) => {
        setLoading(false);
        enqueueSnackbar('Error! Please try again');
        router.push('/home/accounting');
      });
  };
  const addNotification = () => {
    const newNotification = {
      projectId: id,
      pageName: 'ACCOUNTING CLIENT - LEVEL 1',
      title: `ACCOUNTING CLIENT - LEVEL 1 PENDING FOR APPROVAL : ${response?.quote?.projectId}`,
      desc: 'NEW REQUEST COMING FROM ACCOUNTING CLIENT - LEVEL 1 FOR APPROVAL',
      redirectURL: `/accounting-client-level1/${id}`,
    };

    addNewNotification(newNotification)
      .then(() => {
        // enqueueSnackbar('Notification Added');
      })
      .catch((err) => {
        // enqueueSnackbar('Error! Please try again');
      });
  };



  const [showChip, setShowChip] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 350) { 
        setShowChip(true);
      } else {
        setShowChip(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  function handleClose() {
    setIsAlertOpen(false)
  }
  async function confirmDeleteClick() {
    setIsAlertOpen(false)

    await deleteUploadedManualFile(
      id,
      deleteManualBody
    )
      .then((resp) => {
        setRefresh(refresh + 1);
        enqueueSnackbar("Selected Manual File Deleted");
        setAlertTitle("")
        setDeleteManualBody({})
        setSelectedFilesName()
      })
      .catch((err) => {
        // console.log('err', err);
        enqueueSnackbar("Failed to Delete", { variant: 'error' });
        setAlertTitle("")
        setDeleteManualBody({})
        setSelectedFilesName()
      });
  }
  async function updateManualFiles(fieldName, url) {
    let reqData = { "CommercialManualInvoice": [url.at(-1)] }
    await uploadManualFile(
      id,
      reqData
    )
      .then((resp) => {
        setRefresh(refresh + 1);
        enqueueSnackbar("Added Manual Files");
      })
      .catch((err) => {
        // console.log('err', err);
        enqueueSnackbar("Failed to Upload", { variant: 'error' });
      });

    
  }

  async function openDeleteConfirmationModal(filesName) {
    setIsAlertOpen(true)
    let reqData = { key:"CommercialManualInvoice",
                      fileUrl: filesName 
                    }
        setAlertTitle("Do you want to delete below PO Manual File ?")
        setDeleteManualBody(reqData)
        setSelectedFilesName(filesName)
  }

  const HandleAddAdvanceAmmount = async () => {
    if (advance) {
      const payload = {
        advanceAmount: advance,
      };
      await addAdvanceAmmount(id, payload)
        .then((res) => {
          setResponse({
            ...response,
            quote: { ...response.quote, proformaInvoice: res.proformaInvoice },
          });

          setOpenModal(false);
          setAdvance();
        })
        .catch((error) => {
          // console.log(error?.data, 'eroorrr');
          alert('Error:', error?.data);
        });
    } else {
      alert('Enter Advance Ammount');
    }
  };

  const onDownloadClick = async (id) => {
    try {
      let resp = await generateClientLedgerPdf(id);
      enqueueSnackbar('Create success!');
      console.log("resp123", resp)
    } catch (error) {
      enqueueSnackbar('Error in creating pdf!', { variant: 'error' });
      console.log("error", error);
    }
  }


  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <DeleteManualAlert
        alertTitle={alertTitle}
       isAlertOpen={isAlertOpen}
       handleClose={handleClose}
       handleYesClick={confirmDeleteClick}
       fileName={seletedFilesName}
      />
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogTitle>Enter Advance Ammount</DialogTitle>
        <DialogContent sx={{ paddingBottom: 3 }}>
          <Box display="flex" flexDirection="column">
            <TextField value={advance} onChange={(e) => setAdvance(e.target.value)} />
            <Button
              style={{ textTransform: 'uppercase' }}
              variant="contained"
              sx={{ mt: 1, py: 2 }}
              onClick={() => HandleAddAdvanceAmmount()}
            >
              Submit
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Box display="flex">
        <Box sx={{ width: '100%', display: "flex", justifyContent: 'space-between', flexDirection: 'column' }}>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Box
              sx={{ width: '30%', borderRight: '1px solid white', pr: 3, mb: 2 }}
              display={'flex'}
              justifyContent={'space-between'}
            >
              <Box>
                <CustomBreadcrumbs
                  heading="ACCOUNTING CLIENT"
                  links={[
                    { name: 'Home', href: '/home' },
                    { name: 'ACCOUNTING LIST', href: '/home/accounting' },
                    { name: 'ACCOUNTING CLIENT Level 1', href: '/home' },
                  ]}
                  sx={{ mb:2, color: 'white' }}
                />
                <Stack>
                  <Stack sx={{ mb:2 }} direction={'row'} justifyContent={'space-between'}>
                  <Chip
                      textTransform="uppercase"
                      label={`PROJECT ID : ${response?.quote?.projectId}`}
                      style={{
                          background: 'gray',
                          color:'black',
                          fontWeight:'bold',
                          width: '100%'
                        }}
                    />
                  </Stack>
                  <Stack direction={'row'} justifyContent={'space-between'}>

                  <Typography
                    sx={{ color: 'white' }}
                    variant="span"
                    gutterBottom
                    textTransform="uppercase"
                  >
                    CURRENCY : {response?.quote?.currency?.symbol} {response?.quote?.currency?.abbreviation}
                  </Typography>
                  </Stack>
                </Stack>
              </Box>
              <Typography
                sx={{ color: 'white' }}
                variant="h4"
                gutterBottom
                textTransform="uppercase"
              >
                Level 1
              </Typography>
            </Box>
            <Box
              sx={{ width: '70%', pr: 2, pl: 2 }}
              display={'flex'}
              justifyContent={'space-between'}
            >
              <Box sx={{ borderRight: '1px solid white', pr: 3, mb: 5, mt: 4, width: '50%' }}>
                <ContractAmountDisplay data={response?.quote} />
                <Stack spacing={1}>
                  <Box display={'flex'} sx={{ color: 'white' }} justifyContent={'space-between'}>
                    <Typography textTransform="uppercase">Amount Received</Typography>
                    <Typography
                      textTransform="uppercase"
                      variant="subtitle1"
                     
                      sx={{ ml: 3 }}
                    >
                      {formatCurrencyWithSymbol(
                        _.sumBy(response?.quote?.proformaInvoice, (i) => i.amount),
                        response?.quote?.currency?.abbreviation
                      )}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box display={'flex'} sx={{ color: 'white' }} justifyContent={'space-between'}>
                    <Typography textTransform="uppercase">Balance Amount</Typography>
                    <Typography
                      textTransform="uppercase"
                      variant="subtitle1"
                     
                      sx={{
                        color: 'white',
                        textDecoration: 'underline',
                        textDecorationColor: 'red',
                        ml: 3,
                      }}
                    >
                      {formatCurrencyWithSymbol((calculateFinalAmount(response?.quote)+(response?.quote?.cgst/100)*(calculateContractAmount(response?.quote)-calculateDiscountAmount(response?.quote)+response?.quote?.airFreight)+(response?.quote?.sgst/100)*(calculateContractAmount(response?.quote)-calculateDiscountAmount(response?.quote)+response?.quote?.airFreight)+(response?.quote?.igst/100)*(calculateContractAmount(response?.quote)-calculateDiscountAmount(response?.quote)+response?.quote?.airFreight)+(response?.quote?.vat/100)*(calculateContractAmount(response?.quote)-calculateDiscountAmount(response?.quote)+response?.quote?.airFreight))-
                        _.sumBy(response?.quote?.proformaInvoice, (i) => i.amount),
                        response?.quote?.currency?.abbreviation
                      )}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
              <Box
                sx={{
                  pr: 3,
                  pl: 3,
                  mb: 5,
                  mt: 4,
                  pt: 4,
                  // borderRight: '1px solid white',
                  color: 'white',
                  width: '50%',
                }}
              >
                <Box display="flex" sx={{ width: '100%', mb: 1 }}>
                  <Box sx={{ width: '40%' }}>PROJECT ID</Box>:
                  <Box sx={{ width: '60%', pl: 3 }}>{response?.quote?.projectId}</Box>
                </Box>
                <Box display="flex" sx={{ width: '100%', mb: 1 }}>
                  <Box sx={{ width: '40%' }}>RFQ NO.</Box>:
                  <Box sx={{ width: '60%', pl: 3, wordWrap: 'break-word' }}>{response?.quote?.RFQ}</Box>
                </Box>
                <Box display="flex" sx={{ width: '100%', mb: 1 }}>
                  <Box sx={{ width: '40%' }}>RFQ NAME</Box>:
                  <Box sx={{ width: '60%', pl: 3, wordWrap: 'break-word' }}>{response?.quote?.RFQName}</Box>
                </Box>
                <Box display="flex" sx={{ width: '100%', mb: 1 }}>
                  <Box sx={{ width: '40%' }}>PO NUMBER</Box>:
                  <Box sx={{ width: '60%', pl: 3, wordWrap: 'break-word' }}>{response?.quote?.clientPO}</Box>
                </Box>
                <Box display="flex" sx={{ width: '100%', mb: 1 }}>
                  <Box sx={{ width: '40%' }}>ACCEPTANCE DATE</Box>:
                  <Box sx={{ width: '60%', pl: 3 }}>{ moment( response?.quote?.AcceptedPODate).format('DD-MM-YYYY')}</Box>
                </Box>
              </Box>
            
              <Box
          sx={{
            color: 'white',
            pr: 2,
            pl: 2,
            mb: 5,
            width: '30%',
            borderLeft: '1px solid white',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography>Quick Actions</Typography>
          <Box sx={{ width: '100%', display: 'flex', flexWrap: "wrap" , justifyContent:'space-between'}}>
            <Button
              variant="contained"
              sx={{
                color: 'black',
                backgroundColor: '#D9D9D9',
                px: 5,
                borderRadius: 5,
                my: 2,
                mr: 2
              }}
            >
              Accounting
            </Button>
            <HasAccess roles={['admin']} permissions={['accounting_frontend_view_ledger']}>
              <Grid item>
              {backendAuth === false ? 
                <Ledger
                  data={response?.quote}
                  invoices={response?.quote?.proformaInvoice}
                  currency={response?.quote?.currency?.symbol}
                />: 
                <Button
                variant="contained"
                sx={{
                  color: 'black',
                  backgroundColor: '#D9D9D9',
                  px: 5,
                  borderRadius: 5,
                  my: 2,
                  mr: 2
                }}
              >
                View Ledger
              </Button>}
              {backendAuth === true ? 
              <Box display="flex" sx={{ width: '100%', mb: 1 }}>
                <UploadManualFiles
                    label="INVOICE - EXT"
                    path="manual/invoice"
                    // disabled={true}
                    value={response?.quote?.CommercialManualInvoice ? response?.quote?.CommercialManualInvoice : []}
                    sx={{
                    //   backgroundColor: '#D9D9D9',
                    //   // p: 2,
                    //   color: 'black',
                    //   borderRadius: 5,
                    //   mt: 5,
                      width: '100%',
                    }}
                    setValue={(val) => updateManualFiles('invoice', val)}
                    deleteSelectedFile={(filesName)=>openDeleteConfirmationModal(filesName)}
                  />
              </Box>:null}
              </Grid>
            </HasAccess>
            {/* <Button
              variant="contained"
              sx={{
                color: 'black',
                backgroundColor: '#D9D9D9',
                px: 5,
                borderRadius: 5,
                my: 2,
                mr: 2
              }}
              onClick={()=> onDownloadClick("659edacd010a4ff8b3bf5d0c")}
            >
              Client Ledger
            </Button> */}
          </Box>
        </Box>
            </Box>
          </Box>
          {showChip && (
            <Box
              sx={{
                marginTop: '10px',
                marginBottom: '10px',
              }}>
            <Chip
              textTransform="uppercase"
              label={`PROJECT ID : ${response?.quote?.projectId}`}
              style={{
                width: '23.2%',
                marginLeft:13,
                background: 'gray',
                color:'black',
                fontWeight:'bold'
              }}
            />
                </Box>
            )}
          <Box display={'flex'} >
            <HasAccess
              roles={['admin']}
              permissions={['accounting_frontend_view']}
            // renderAuthFailed={<p>You are not authorized to access!</p>}
            >
              <Button
                href={`/frontend-acc-client/${id}`}
                variant="contained"
                sx={{
                  backgroundColor: 'gray',
                  borderRadius: '0px',
                  marginRight: '10px',
                  ml:2,
                  color:'black',
                  borderTop: 'none'
                }}
              >
                Accounting
              </Button>
            </HasAccess>
            <HasAccess
              roles={['admin']}
              permissions={['accounting_client_backend_level1_view']}
            // renderAuthFailed={<p>You are not authorized to access!</p>}
            >
              <Button
                href={`/accounting-client-level1/${id}`}
                variant="contained"
                sx={{
                  backgroundColor: 'gray',
                  borderRadius: '0px',
                  marginRight: '10px',
                  color:'black',
                  borderTop: '2px solid red'
                }}
               >
                Accounting CLIENT L1
              </Button>
            </HasAccess>
            <HasAccess
              roles={['admin']}
              permissions={['accounting_client_backend_level2_view']}
            // renderAuthFailed={<p>You are not authorized to access!</p>}
            >
              <Button
                href={`/accounting-client-level2/${id}`}
                variant="contained"
                sx={{
                  backgroundColor: 'gray',
                  borderRadius: '0px',
                  marginRight: '10px',
                  color:'black',
                  borderTop: 'none'
                }}
              >
                Accounting CLIENT L2
              </Button>
            </HasAccess>
            {backendAuth === false ? 
            <>
            <HasAccess
              roles={['admin']}
              permissions={['accounting_vendor_backend_level1_view']}
            // renderAuthFailed={<p>You are not authorized to access!</p>}
            >
              <Button
                href={`/acc-vendor/l1/${id}`}
                variant="contained"
                sx={{
                  backgroundColor: 'gray',
                  borderRadius: '0px',
                  marginRight: '10px',
                  color:'black',
                  borderTop: 'none'
                }}
               >
                Accounting Vendor L1
              </Button>
            </HasAccess>
            <HasAccess
              roles={['admin']}
              permissions={['accounting_vendor_backend_level2_view']}
            // renderAuthFailed={<p>You are not authorized to access!</p>}
            >
              <Button
                href={`/acc-vendor/l2/${id}`}
                variant="contained"
                sx={{
                  backgroundColor: 'gray',
                  borderRadius: '0px',
                  marginRight: '10px',
                  color:'black',
                  borderTop: 'none'
                }}>
                Accounting Vendor L2
              </Button>
            </HasAccess>
            </>
            : null}
            {backendAuth === false ? 
          <HasAccess sx={{m:0, p:0}} roles={['admin']} permissions={['vendor_home_default_view']}>
            <Button
              disabled={response?.quote?.isCancelled}
              onClick={() => router.push(`/quote/${id}/vendor`)}
            variant="contained"
            sx={{
              backgroundColor: 'gray',
              borderRadius: '0px',
              marginRight: '10px',
              color: 'black'
            }}
            >
              Vendor HomePage
            </Button>
          </HasAccess>: null}
            <HasAccess sx={{m:0, p:0}} roles={['admin']} permissions={['quote_backend_page_1_default_view']}>
            <Button
              onClick={() => router.push(`/quote/l1/${id}`)}
              variant="contained"
              sx={{
                backgroundColor: 'gray',
                borderRadius: '0px',
                marginRight: '10px',
                color:'black',
                borderTop: 'none'
              }}
            >
              QUOTE L1
            </Button>
          </HasAccess>
            <HasAccess sx={{m:0, p:0}} roles={['admin']} permissions={['purchase_order_backend_level1_view']}>
            <Button
              onClick={() => router.push(`/poStage/purchaseOrderl1/${id}`)}
              variant="contained"
              sx={{
                backgroundColor: 'gray',
                borderRadius: '0px',
                marginRight: '10px',
                color:'black',
                borderTop: 'none'
              }}
            >
              PO L1
            </Button>
          </HasAccess>
          {backendAuth === false ?
          <HasAccess sx={{m:0, p:0}} roles={['admin']} permissions={['delivery_l2']}>
            <Button
              href = {`/delivery/deliveryl2/${id}`}
              sx={{
                backgroundColor: 'gray',
                borderRadius: '0px',
                marginRight: '10px',
              }}
            >
              Delivery
            </Button>
          </HasAccess>:null}
          </Box>
        </Box>
       


      </Box>


      <AccClientLevel1Table response={response?.quote} setRefresh={setRefresh} refresh={refresh} setLoading={setLoading} />
    </Container>
  );
}
